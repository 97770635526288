<template>
  <div>
    <div class="list-item" v-for="item in storeList" :key="item.id" @click="goStore(item)">
      <div class="img"><img v-lazy="item.logo" alt=""></div>
      <div class="info">
        <div class="title">{{item.title}}</div>
        <div class="info-line">
          <rate
            v-model="item.star"
            allow-half
            size="11px"
            void-icon="star"
            void-color="#eee"
            color="#ff6600"
            readonly
          />
          <span class="score">{{item.star == 0? '暂无评分':item.star}}</span>
          <span class="sale">月售 {{item.sales}}</span>
          <span class="distance">{{item.distance}}</span>
        </div>
        <!-- <div class="info-line">
          <span>{{item.type}}&nbsp;|&nbsp;{{item.address}}</span>
          <span class="status">{{item.status}}</span>
        </div> -->
        <!-- <div class="coupon-list" v-for="list in item.discounts" :key="list.id">
          <div class="head">{{list.head}}</div>
          <span class="text">{{list.info}}</span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Rate } from 'vant';
export default {
  props:{
    storeList:{
      type:Array
    },
  },
  components:{
    Rate
  },
  methods: {
    goStore(item) {
      this.$router.push(`/storedet?merch_id=${item.merch_id}&store_id=${item.id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.list-item{
  width: 96%;
  height: auto;
  padding: 10px 8px;
  display: flex;
  margin: 10px auto;
  font-weight:500;
  color:#000;
  border-radius: 3px;
  background: #fff;
  border-bottom:1px solid #f4f4f4;
  .img{
    width:55px;
    height: 55px;
    margin-right: 10px;
    img{
      width:100%;
      height: 100%;
    }
  }
  .info{
    flex:1;
    // padding-bottom: 12px;
    .title{
      font-size: 14px;
      width:80%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      white-space: nowrap;
    }
    .info-line{
      padding-top:2px;
      font-size: 11px;
      line-height: 11px;
      margin-bottom: 12px;
      .score{
        color:#ff6600;
        margin-left: 4px;
      }
      .sale{
        margin-left: 10px;
      }
      .distance{
        float: right;
      }
      .status{
        float: right;
        border:1px solid #8c8c8c;
        color:#8c8c8c;
        padding:1px 4px;
        border-radius: 2px;
      }
    }
    .coupon-list{
      font-size: 11px;
      height: 14px;
      margin-bottom: 8px;
      .head{
        width:14px;
        height: 14px;
        line-height: 14px;
        text-align: center;
        background: #ff6600;
        font-size: 10px;
        color:#fff;
        display: inline-block;
        margin-right: 8px;
        border-radius: 2px;
        padding-top:1px;
      }
      .text{
        line-height: 14px;
      }
    }
  }
}
</style>

<template>
  <div class="nav-swiper">
    <Swipe
      @change="goUrl"
      :touchable="tabBar.touch_stats"
      :show-indicators="tabBar.showIndicators"
      class="nav-swiper-con"
    >
      <SwipeItem v-for="(item,index) in tabBar.listTab" :key="index">
        <div class="labes">
          <div
            v-for="(items,indexs) in item"
            @click="goUrl(items.url)"
            :key="indexs"
            class="tabchild"
          >
            <div class="grid-content bg-purple">
              <div class="labround">
                <img :src="items.pic" alt />
              </div>
              <span>{{items.title}}</span>
            </div>
          </div>
        </div>
      </SwipeItem>
    </Swipe>
  </div>
</template>
<script>
import { Swipe, SwipeItem } from "vant";
export default {
  components: {
    Swipe,
    SwipeItem
  },
  data() {
    return {
      Alldata: {},
      tabBar: {
        listTab: [],
        touch_stats: "",
        showIndicators: ""
      },
    }
  },
  props:{
    initDataNav: {
      type: Array
    }
  },
  mounted() {
    this.$nextTick(function() {
      const tabSum = 10;
      let tabKey = 0;
      const tabArr = [[]];
      let tabArrKey = 0;
      const nowdata = this.initDataNav;
      // 导航
      if (nowdata.length > 0) {
        tabArr[tabKey] = [];
        for (let i = 0; i < nowdata.length; i++) {
          if (i && i % tabSum === 0) {
            tabKey++;
            tabArrKey = 0;
            tabArr[tabKey] = [];
          }
          tabArr[tabKey][tabArrKey] = nowdata[i];
          tabArrKey++;
        }
      }
      const key1 = "listTab";
      const key2 = "touch_stats";
      const key3 = "showIndicators";
      this.tabBar[key1] = tabArr; // 导航数据
      this.tabBar[key2] = tabArr.length > 1 ? true : false; // 滑动开关
      this.tabBar[key3] = tabArr.length > 1 ? true : false; // 点开关
    });
  },
  methods: {
    goUrl(url) {
      console.log(url)
      this.$router.push({ path: url });
    }
  }
};
</script>

<style lang="scss" scoped>
.nav-swiper {
  width: 100%;
}
.nav-swiper-con {
  width: 100%;
  height: 180px;
}
.tabchild {
  width: 20%;
  height: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 0.14rem;
}
.labes {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 0.16rem 0;
  flex-wrap: wrap;
  background: #fff;
}
.grid-content {
  color: #7d7d7d;
  height: 1.5rem;
}
.grid-content span {
  position: relative;
  top: 0.1rem;
}
.labround {
  width: 50px;
  height: 50px;
  margin: 0 auto;
}
.labround img {
  width: 40px;
  height: 40px;
  margin-top: 0.1rem;
  border-radius: 20px;
}
</style>
